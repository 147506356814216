import '../scss/main.scss';
import  $ from "jquery";
import 'bootstrap';
import 'bootstrap-select';
import "bootstrap-select/sass/bootstrap-select.scss";
import AOS from 'aos';

import LDIHSwipers from './swipers';
import formElt from './form';

const general = {
  "mqBreakpoints": {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1156
  },

  "getScrollDistance": function() {
    let scrollDistance = 0;
    scrollDistance = $(window).scrollTop();
    $(window).scroll(function () {
      scrollDistance = $(window).scrollTop();
    })
    return scrollDistance;
  },

  "scrollBkp": 20, 

  "setPaddingTop": function () {
    let self = this;
    if ($('.js-set-padding-top').length && $('header').length) {
      let root = document.documentElement,
        headerOffset = 0,
        headerHeight;
        headerHeight = $('header').innerHeight();
      if ($(window).width() >= this.mqBreakpoints.lg) {
        headerOffset = 0;
      }
      
      if (headerHeight + headerOffset < 50) {
        root.style.setProperty('--header-height', "50px");
        if ($(window).width() >= self.mqBreakpoints.lg) {
          root.style.setProperty('--header-height', "130px");
        }
      } else {
        root.style.setProperty('--header-height', headerHeight + headerOffset + "px");
      }
    }
  },


  "setNavbarCollapseTop": function() {
    let self = this;
    if ($('.navbar-collapse').length && $('.page-header').length ) {
      let root = document.documentElement;
      let headerHeight = $('.page-header').innerHeight();
      let headerOffset = 0;
      let scrollDistance = this.getScrollDistance();
      scrollDistance = $(window).scrollTop();
      if ($(window).width() >= self.mqBreakpoints.md && scrollDistance >= self.scrollBkp) {
        $('.navbar-collapse').css('top', headerHeight - 42);
        root.style.setProperty('--true-header-height', headerHeight - 42 + 'px');
      } else {
        $('.navbar-collapse').css('top', headerHeight);
        root.style.setProperty('--true-header-height', headerHeight + 'px');
      }
    }
  },


  "preventScrollOpenedMenu": function () {
    let self = this;
    var $window = $(window),
        $body = $("body"),
        scrollDistance = self.getScrollDistance();
      $('.page-header .navbar-collapse').on('show.bs.collapse', function (e) {
          // Get the scroll distance at the time the modal was opened
          self.setNavbarCollapseTop();
          scrollDistance = $window.scrollTop();
       
          if (scrollDistance >= self.scrollBkp) {
            $('.page-header').addClass('scrolled-menu');
          }
          // Pull the top of the body up by that amount
          $body.css("top", scrollDistance * -1);
          $body.addClass("opened-menu");
      })
      $('.page-header .navbar-collapse').on('hide.bs.collapse', function () {
          // Remove the negative top value on the body
          $body.removeClass("opened-menu"); 
          $body.css("top", "");
          $('.page-header').removeClass('scrolled-menu')
          // Set the window's scroll position back to what it was before the modal was opened
          $window.scrollTop(scrollDistance); 
          
      })
  },


  "closeMenu": function() {
    $('#mainmenu').collapse('hide');
    $('.main-nav').removeClass('menu-open');
    $('html').removeClass('menu-open');
    $('body').removeClass("opened-menu"); 
    $('body').css("top", "");
  },

  "addClassOnOpenedMenu": function () {
    if ($(window).width() <= this.mqBreakpoints.md) {
      $('.main-nav').on('show.bs.collapse', function () {
        $(this).addClass('menu-open');
        $('html').addClass('menu-open');
      }).on('hidden.bs.collapse', function () {
        $(this).removeClass('menu-open');
        $('html').removeClass('menu-open');
      })
    } else {
        $(this).removeClass('menu-open');
        $('html').removeClass('menu-open');
    }
  },

  "addActiveClassOnScroll": function () {
    let self = this;
    if ($('.js-active-class-onscroll').length) {
      var addClass = function () {
        var scroll = $(window).scrollTop();
        if (scroll >= self.scrollBkp) {
          $(".js-active-class-onscroll").addClass("active");
        } else {
          $(".js-active-class-onscroll").removeClass("active");
        }
      }
      addClass();
      $(window).scroll(function () {
        addClass();
      });
    }
  },

  "closeLangSwitcher": function() {
    let self = this;
    if ($('#langSwitcher').length) {
      var scroll = $(window).scrollTop();
      $(window).scroll(function () {
        if (scroll >= self.scrollBkp) {
          $('#langSwitcher').dropdown('hide');
          $('#langSwitcher').attr('aria-expanded', 'false');
        }
      })
    }
  },

  "tableHeight": function() {
    if ($('.js-table-container').length) {
      let cellElts = $('.js-table-container').find('.board__cell');
      for (let i = 2; i <= cellElts.length; i++) {
        let cell = $('.js-table-container').find('.board__cell:nth-child('+i+')');
        var maxHeight = 0;
        cell.each(function() {
          if ($(this).innerHeight() > maxHeight) {
            maxHeight = $(this).innerHeight();
          }
        })
        cell.css('height', maxHeight);
      }
    }
  },

  "setSameHeight": function () {
    if ($('.js-same-height-container').length) {
      var setHeight = function () {
    
        $('.js-same-height-container').each(function () {
          var maxHeight = 0;
          var elements = $(this).find('.js-same-height');
          var elementsMax = $(this).find('.js-same-height-max');
          elements.each(function () {
            if ($(this).outerHeight() > maxHeight) {
              maxHeight = $(this).outerHeight();
            }
          });
          elements.css('height', maxHeight);
          if ($('.js-same-height-max').length) {
            elementsMax.css('max-height', maxHeight, true);
          }
        });
      };

      if ($('.js-same-height-container').hasClass('-tablet')) {
        let that = $(this);
        if ($(window).width() >= this.mqBreakpoints.md) {
          setHeight();
        } 
      } else {
        setHeight();
      }

    }
  },

  "getViewPortHeight": function() {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  },

  "closeDropdown": function() {
    if ($('.js-close-dropdown-menu').length) {
      $('.js-close-dropdown-menu').on('click', function(e) {
        $(this).dropdown('hide');
      })
    }
  },

  "detectClickOutsideActive": function(event, el) {
    var $target = $(event.target);
    if(!$target.closest(el).length) {
      return true;
    }    
  },

  "initSelectLists": function () {
    if ($(window).width() >= this.mqBreakpoints.md) {
      if ($('.js-select').length) {
        $('.js-select').each(function() {
          if ($(this).hasClass('js-inited')) {
            return;
          } else {
            $(this).addClass('js-inited');
            $(this).selectpicker({
              multipleSeparator: '\u00a0|\u00a0'
            });
          }
        })
      }
    }
   },

   "removeElts": function() {
     let self = this;
     if ($('.js-removeEltsMobileContainer').length) {
        $('.js-removeEltsMobileContainer').each(function() {
          let eltsToKeep = $(this).attr('data-elts');
          let elts = $(this).find('.js-removeEltsMobile');
          elts.removeClass('js-removeEltsMobile');
          if ($(window).width() < self.mqBreakpoints.md) {
            elts.slice(eltsToKeep).remove();
          }
        })
     }
   },

   "scrollAnimation": function() {
     let self = this;
      AOS.init({
          disable: false,
          once: true, // whether animation should happen only once - while scrolling down
          offset: 0, // offset (in px) from the original trigger point
          duration: 1000, // values from 0 to 3000, with step 50ms
      });

      let aosElts = $('[data-aos-offset="250"]');

      let aosMobileLEts = $('[data-aos-delay-mobile]');
      let aosTabletLEts = $('[data-aos-delay-tablet]');
      
      if ($(window).width() < self.mqBreakpoints.md) {
        aosElts.each(function() {
          $(this).attr("data-aos-offset", "130");
        })
      } else if ($(window).width() === self.mqBreakpoints.md) {
        $(this).attr("data-aos-offset", "135");
      } else {
        aosElts.each(function() {
          $(this).attr("data-aos-offset", "250");
        })
      }

      if ($(window).width() < self.mqBreakpoints.sm) {
        aosMobileLEts.each(function() {
          let value = $(this).attr("data-aos-delay-mobile");
          $(this).attr("data-aos-delay", value);
        })
      }

      if ($(window).width() >= self.mqBreakpoints.md && $(window).width() < self.mqBreakpoints.lg) {
        aosTabletLEts.each(function() {
          let value = $(this).attr("data-aos-delay-tablet");
          $(this).attr("data-aos-delay", value);
        })
      }

      AOS.refreshHard();
   },

   "changeContentwRadio": function() {
    if ($('.js-radio-show-content-container').length) {
        let container = $('.js-radio-show-content-container'),
            content = $('.js-radio-show-content'),
            activeContent;
        const showContent = function() {
            content.removeClass('d-block');
            content.addClass('d-none');
            let checkedVal;
            $('.js-radio-show-content-radio:checked').each(function() {
                checkedVal = $(this).val();
                activeContent = container.find('.js-radio-show-content[data-value=' + checkedVal + ']');
                activeContent.addClass('d-block');
                activeContent.removeClass('d-none');
            })
        };
        showContent();
        container.find('.js-radio-show-content-radio').on('change', function() {
            showContent();
        });
    }
  },

  "radioToSelect": function() {
    let self = this;
    if ($('.js-radio-to-select').length) {
      $('.js-radio-to-select').each(function() {
        let that = $(this);
        if ($(this).find('select').length) {
          return;
        }

        if ($(window).width() < self.mqBreakpoints.md) {
          let container = $(this).find('.js-radio-to-select-container');
          let radios = $(this).find('input[type="radio"]');
          let selectGroup = $('<div class="form-group form-select"></div>')
          let newSelect = $('<select class="js-select"></select>');
          newSelect.attr('id', radios.attr('name'))
          radios.each(function() {
            let str = $(this).next('label').text();
            let value = $(this).attr('id');
            newSelect.append($('<option>').val(value).text(str));
          })
          selectGroup.append(newSelect);
          container.append(selectGroup);
        }
      })
    }
  },

  "backTop": function() {
    if ($('.js-back-to-top').length) {
      let scrollDistance = $(window).scrollTop();
      $(window).on('scroll',function () {
        scrollDistance = $(window).scrollTop();
        if (scrollDistance > $(window).height() * 0.5) {
          $('.js-back-to-top').removeClass('hidden');
        } else {
          $('.js-back-to-top').addClass('hidden');
        }
      });

      $('.js-back-to-top').on('click', function(e) {
        e.preventDefault();
        window.scrollTo({top: 0, behavior: 'smooth'});
      })
    }
  }

}

window.LDIHfunctions = general;

export default general;

(function($){
  $(document).ready(function () {

    LDIHSwipers.useCasesSwiper();
    LDIHSwipers.boardSwiper();
    LDIHSwipers.shapesSwiper();
    LDIHSwipers.stepperSwiper();
    LDIHSwipers.filtersSwiper();
    LDIHSwipers.twoSlidesSwiper();

    general.preventScrollOpenedMenu();
    general.addClassOnOpenedMenu();
    general.addActiveClassOnScroll();
    general.getViewPortHeight();
    general.closeDropdown();
    general.setPaddingTop();
    general.initSelectLists();
    general.setSameHeight();
    general.removeElts();
    general.scrollAnimation();
    general.closeLangSwitcher();
    general.changeContentwRadio();
    general.radioToSelect();
    general.backTop();

    formElt.filePreview(document.querySelector('.js-file-input'));
    formElt.addChip();
    formElt.removeChip();
    formElt.goNextTab();

    if ($('.js-clearInput').length) {
      document.querySelector('.js-clearInput').addEventListener('click', function(e) {
        formElt.clearFileInput(document.querySelector('.js-clearInput'), document.querySelector('.js-file-input'),document.querySelector('.js-img-preview'));
      })
    }

    if ($('.js-table-modal').length) {
      $('.js-table-modal').on('shown.bs.modal', function() {
        LDIHSwipers.boardSwiper();
        general.tableHeight();
      })
    } else {
      general.tableHeight();
    }

    setTimeout(function(){general.setNavbarCollapseTop();}, 300);


    var windowWidth = $(window).width();
    $(window).on('resize', function(){
      general.getViewPortHeight();
      if ($(window).width() != windowWidth) {
        $('.dropdown-toggle').dropdown('hide');
        AOS.refreshHard()

        setTimeout(function(){
          general.setNavbarCollapseTop();
          LDIHSwipers.useCasesSwiper();
          LDIHSwipers.boardSwiper();
          LDIHSwipers.shapesSwiper();
          LDIHSwipers.twoSlidesSwiper();
          LDIHSwipers.stepperSwiper();
          LDIHSwipers.filtersSwiper();
          general.tableHeight();
        }, 400);

        general.setPaddingTop();
        general.closeMenu();
        general.setSameHeight();
        general.closeLangSwitcher();
        general.radioToSelect();
      }  
    });
    
  });


})($);
