
import general from './main';
import  $ from "jquery";

import Swiper, { Navigation, Pagination } from 'swiper';
import 'swiper/swiper-bundle.css';

//configure Swiper to use modules
Swiper.use([Navigation, Pagination]);

const LDIHSwipers = {

    "initSwiperBehavior": function(swiperEl) {
        swiperEl.init();
    },

    "setSwiperActiveEl": function(swiperElt, currentSwiper, activeEl) {
        let sticker = swiperElt.find(activeEl);
        let activeSlideIndex = currentSwiper.activeIndex;
        for (let i = 0; i < sticker.length; i++) {
          sticker[i].classList.remove('active');
        }
        sticker[activeSlideIndex].classList.add('active');
     },
  
     "killSwiper": function(swiperEl) {
       swiperEl.destroy(true, true);
     },

     "stepperSwiper": function() {
       let self = this;
       if ($('.stepper-swiper-container').length) {
        $('.stepper-swiper-container').each(function () {
          let slides = $(this).find('.swiper-slide');
          let activeStepIndex;

          for (let i = 0; i < slides.length; i++ ) {
            if ($(slides[i]).hasClass('active')) {
              activeStepIndex = i;
            }
          }
          
          $(this).find('.swiper-wrapper').removeClass('no-translate');
          
            var swiperStepper = new Swiper($(this)[0], {
                slidesPerView: 3.125,
                spaceBetween: 0,
                preventClicks: true,
                init: false,
                breakpoints: {
                    768: {
                      slidesPerView: 4.125,
                    },
                },
                on: {
                    init: function () {
                        this.setTranslate(-15);
                        self.initSwiperBehavior(this);
                        this.slideTo(activeStepIndex);
                    },
                    resize: function () {
                      if ($(window).width() < general.mqBreakpoints.md) {
                        this.init();
                        self.initSwiperBehavior(this);
                        this.setTranslate(-15);
                        this.slideTo(activeStepIndex);
                      } else {
                        self.killSwiper(this);
                      }
                    },
                }
            });

          if ($(window).width() < general.mqBreakpoints.md) {
            swiperStepper.init();
            swiperStepper.setTranslate(-15);
            swiperStepper.slideTo(activeStepIndex);
            self.initSwiperBehavior(swiperStepper);
          }

        });
      }
     },

     "useCasesSwiper": function() {
       let self = this;
      if ($('.section-cases__swiper').length) {
        $('.section-cases__swiper').each(function () {
            let swiperElt = $(this);
            var swiperUseCase = new Swiper($(this)[0], {
              slidesPerView: 1.25,
              centeredSlides: true,
              spaceBetween: 0,
              observer: true,
              observeParents: true,
              preventClicks: true,
              loop: true,
              speed: 400,
              navigation: {
                  nextEl: '.swiper-button-next',
                  prevEl: '.swiper-button-prev',
              },
              breakpoints: {
                768: {
                  slidesPerView: 'auto',
                },
            },
              on: {
                  init: function () {
                      let activeSlide = this.activeIndex;
                      $('.swiper-slide').each(function() {
                        let slide = $(this);
                        //TODO dont work on ios
                        setTimeout(function(){
                            let activeSlideHeight = $('.swiper-slide')[activeSlide].clientHeight;
                            slide.css('min-height', activeSlideHeight);
                        }, 1000);
                      })
                  },
                  resize: function () {
                    self.initSwiperBehavior(this);
                  },
              }
          });
          self.initSwiperBehavior(swiperUseCase);
        });
      }
     },

    "twoSlidesSwiper": function() {
      let self = this;
     if ($('.Tslides-swiper-container').length) {
       $('.Tslides-swiper-container').each(function () {
           let swiperElt = $(this);
           var swiper2Slides = new Swiper($(this)[0], {
             slidesPerView: 1,
             spaceBetween: 30,
             observer: true,
             observeParents: true,
             preventClicks: true,
             speed: 400,
             navigation: {
                 nextEl: '.swiper-button-next',
                 prevEl: '.swiper-button-prev',
             },
             breakpoints: {
               768: {
                 slidesPerView: 2,
               },
           },
             on: {
                 resize: function () {
                   self.initSwiperBehavior(this);
                 },
             }
         });
         self.initSwiperBehavior(swiper2Slides);
       });
     }
    },

     "boardSwiper": function() {
      let self = this;
      if ($('.board-swiper-container').length) {
        $('.board-swiper-container').each(function () {
          $(this).find('.swiper-wrapper').removeClass('no-translate');
    
            var swiperBoard = new Swiper($(this)[0], {
                slidesPerView: 1,
                spaceBetween: 16,
                init: false,
                centeredSlides: true,
                breakpoints: {
                    750: {
                      slidesPerView: 3,
                    },
                },
                navigation: {
                  nextEl: '.swiper-button-next',
                  prevEl: '.swiper-button-prev',
                },
                on: {
                    init: function () {
                        this.setTranslate(0);
                        self.initSwiperBehavior(this);
                    },
                    resize: function () {
                      if ($(window).width() < general.mqBreakpoints.lg) {
                        this.init();
                        self.initSwiperBehavior(this);
                      } else {
                        self.killSwiper(this);
                      }
                    },
                }
            });

          if ($(window).width() < general.mqBreakpoints.lg) {
            swiperBoard.init();
            self.initSwiperBehavior(swiperBoard);
          }

        });
      }
    },

    "shapesSwiper": function() {
      let self = this;
      if ($('.shapes-swiper-container').length) {
        $('.shapes-swiper-container').each(function () {
          $(this).find('.swiper-wrapper').removeClass('no-translate');
          
            var swiperShapes = new Swiper($(this)[0], {
                slidesPerView: 1,
                spaceBetween: 16,
                preventClicks: true,
                init: false,
                breakpoints: {
                    768: {
                      slidesPerView: 3,
                    },
                },
                pagination: {
                  el: '.swiper-pagination',
                  type: 'bullets',
                },
                on: {
                    init: function () {
                        this.setTranslate(0);
                        self.initSwiperBehavior(this);
                    },
                    resize: function () {
                      if ($(window).width() <= general.mqBreakpoints.md) {
                        this.init();
                        self.initSwiperBehavior(this);
                      } else {
                        self.killSwiper(this);
                      }
                    },
                }
            });

          if ($(window).width() <= general.mqBreakpoints.md) {
            swiperShapes.init();
            self.initSwiperBehavior(swiperShapes);
          }

        });
      }
    },

    "filtersSwiper": function() {
      let self = this;
  
      if ($('.inline-filters-swiper-container').length) {
        $('.inline-filters-swiper-container').each(function () {
          let filtersContainerW = document.querySelector('.inline-filters').offsetWidth;
          let filtersElts = document.querySelectorAll('.inline-filters .inline-filters-li');
          let filtersEltsW = 0;
          for (let i = 0; i < filtersElts.length; i++) {
              filtersEltsW += filtersElts[i].offsetWidth;
          }
  
          $(this).find('.swiper-wrapper').removeClass('no-translate');
          
          var swiperFilters = new Swiper($(this)[0], {
              slidesPerView: 'auto',
              spaceBetween: 25,
              preventClicks: true,
              init: false,
              slideToClickedSlide: true,
              breakpoints: {
                768: {
                  spaceBetween: 28,
                }
              },
              on: {
                  init: function () {
                      this.setTranslate(0);
                  },
                  resize: function () {
                      if (filtersEltsW !== null && filtersEltsW >= filtersContainerW + 4) {
                        self.initSwiperBehavior(this);
                      } else if (this) {
                          this.detachEvents();
                          self.killSwiper(this);

                      }
                  },
              }
          });

          console.log(filtersEltsW)
          console.log(filtersContainerW)

          if (filtersEltsW >= filtersContainerW) {
              swiperFilters.init();
              self.initSwiperBehavior(swiperFilters);
          } else if (swiperFilters.initialized) {
              swiperFilters.detachEvents();
              self.killSwiper(swiperFilters);
          }
      
        });
      }
    },



}

export default LDIHSwipers;