import $ from "jquery";

const formElt = {
    
    "appendErrorMessage": function (input) {
        let template = $($('#formError').html());
        $(template).insertAfter(input);
    },


    "handleInputChange": function(input, jsFormName) {
        let self = this;
        let errorMessage = input.closest('.js-add-form').find('.js-error');
        if (self.checkIfBeforeGroupIsPopulated(input, jsFormName) && errorMessage.length) {
            errorMessage.remove();
        }
    },

    "checkIfBeforeGroupIsPopulated": function (input, jsFormName) {
        let prevGroup = input.prev();
        let inputEl = prevGroup.find(jsFormName);
        if (inputEl.val() === "") {
            return false;
        }
        return true;
    },

    "filePreview": function(input) {
        if (input) {
            input.addEventListener('change', function(e) {
                if (e.target.files && e.target.files[0]) {
                    var reader = new FileReader();
                    reader.onload = function (e) {
                        $('.js-clearInput').removeClass('d-none', true);
                        $('.js-img-preview')
                            .addClass('show')
                            .attr('src', e.target.result);
                    };
                    reader.readAsDataURL(e.target.files[0]);
                }
            })
        }
    },

    "clearFileInput": function(btn, input, img) {
        input.value = "";
        img.src = "";
        btn.classList.add('d-none');
        $('.add-img').removeClass('d-none', true);
    },

    "goNextTab": function() {
        $('.js-next-tab').on('click', function(e){
            e.preventDefault();
            var nextId = $(this).parents('.tab-pane').next().attr("id");
            $('[href="#'+nextId+'"]').tab('show');
        })
    },

    "addChip": function() {
        const self = this;
        if ($('.js-add-chip').length) {
            $('.js-add-chip').each(function(i) {
                let container = $(this).find('.js-add-chip-wrapper');
                let input = $(this).find('.js-add-chip-btn input');
                let btn = $(this).find('.js-add-chip-btn button');
                btn.on('click', function(e) {
                    e.preventDefault();
                    e.stopPropagation();
                    const newVal = input.val();
                    const newChip = '<div class="form-check custom-card-icon col-auto"><span class="form-check-label --outlined --xs --icon">'+ newVal + '<svg class="icon --primary js-remove-chip"><use xlink:href="/img/icons/icons.svg#icon-close"></use></svg></span></div>';
                    container.append(newChip);
                    input.val('');
                    //reinit remove chip function
                    self.removeChip();
                })
            })
        }
    },

    "removeChip": function() {
        if ($('.js-add-chip').length) {
            $('.js-add-chip').each(function() {
                let container = $(this).find('.js-add-chip-wrapper');
                let chip = container.find('.custom-card-icon');
                chip.each(function() {
                    $(this).on('click', function() {
                        $(this).remove();
                    })
                })
            })
        }
    }

};

export default formElt;